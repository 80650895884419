import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';

@NgModule({
  providers: [
    ApiServiceProxies.BookCategoryServiceProxy,
        ApiServiceProxies.JobApplicationServiceProxy,
        ApiServiceProxies.JobCategoriesServiceProxy,
        ApiServiceProxies.AdvertisementCompaniesServiceProxy,
        ApiServiceProxies.MantraServiceProxy,
    ApiServiceProxies.CompanyContactsServiceProxy,
    ApiServiceProxies.JobCompaniesServiceProxy,
    ApiServiceProxies.JobsServiceProxy,
    ApiServiceProxies.BlogsServiceProxy,
    ApiServiceProxies.GalleryCategoriesServiceProxy,
    ApiServiceProxies.TestimonialsServiceProxy,
    ApiServiceProxies.GalleryServiceProxy,
    ApiServiceProxies.CareersServiceProxy,
    ApiServiceProxies.BannerCategoriesServiceProxy,
    ApiServiceProxies.BannersServiceProxy,
    ApiServiceProxies.BlogCategoriesServiceProxy,
    ApiServiceProxies.UserServiceProxy,
    ApiServiceProxies.AuditLogServiceProxy,
    ApiServiceProxies.CachingServiceProxy,
    ApiServiceProxies.ChatServiceProxy,
    ApiServiceProxies.CommonLookupServiceProxy,
    ApiServiceProxies.EditionServiceProxy,
    ApiServiceProxies.FriendshipServiceProxy,
    ApiServiceProxies.HostSettingsServiceProxy,
    ApiServiceProxies.InstallServiceProxy,
    ApiServiceProxies.LanguageServiceProxy,
    ApiServiceProxies.NotificationServiceProxy,
    ApiServiceProxies.OrganizationUnitServiceProxy,
    ApiServiceProxies.PermissionServiceProxy,
    ApiServiceProxies.ProfileServiceProxy,
    ApiServiceProxies.RoleServiceProxy,
    ApiServiceProxies.SessionServiceProxy,
    ApiServiceProxies.TenantServiceProxy,
    ApiServiceProxies.TenantDashboardServiceProxy,
    ApiServiceProxies.TenantSettingsServiceProxy,
    ApiServiceProxies.TimingServiceProxy,
    ApiServiceProxies.UserServiceProxy,
    ApiServiceProxies.UserLinkServiceProxy,
    ApiServiceProxies.UserLoginServiceProxy,
    ApiServiceProxies.WebLogServiceProxy,
    ApiServiceProxies.AccountServiceProxy,
    ApiServiceProxies.TokenAuthServiceProxy,
    ApiServiceProxies.TenantRegistrationServiceProxy,
    ApiServiceProxies.HostDashboardServiceProxy,
    ApiServiceProxies.PaymentServiceProxy,
    ApiServiceProxies.InvoiceServiceProxy,
    ApiServiceProxies.SubscriptionServiceProxy,
    ApiServiceProxies.InstallServiceProxy,
    ApiServiceProxies.UiCustomizationSettingsServiceProxy,
    ApiServiceProxies.PayPalPaymentServiceProxy,
    ApiServiceProxies.StripePaymentServiceProxy,
    ApiServiceProxies.DashboardCustomizationServiceProxy,
    ApiServiceProxies.WebhookEventServiceProxy,
    ApiServiceProxies.WebhookSubscriptionServiceProxy,
    ApiServiceProxies.WebhookSendAttemptServiceProxy,
    ApiServiceProxies.UserDelegationServiceProxy,
    ApiServiceProxies.DynamicPropertyServiceProxy,
    ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
    ApiServiceProxies.DynamicEntityPropertyServiceProxy,
    ApiServiceProxies.DynamicPropertyValueServiceProxy,
    ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
    ApiServiceProxies.TwitterServiceProxy,
    //Patro
    ApiServiceProxies.PatroFacilitiesServiceProxy,
    ApiServiceProxies.PatroDownloadsServiceProxy,
    ApiServiceProxies.PatroFrontendServiceProxy,
    ApiServiceProxies.PatroPagesServiceProxy,
    ApiServiceProxies.PatroEventsServiceProxy,
    ApiServiceProxies.PatroVideosServiceProxy,
    ApiServiceProxies.PatroVideoCategoriesServiceProxy,
    ApiServiceProxies.AdvertisementsServiceProxy,
    ApiServiceProxies.NoticesServiceProxy,
    ApiServiceProxies.TeamMembersServiceProxy,

    // rashifal
    ApiServiceProxies.DailyRashifalsServiceProxy,
    ApiServiceProxies.WeeklyRashifalsServiceProxy,
    ApiServiceProxies.MonthlyRashifalsServiceProxy,
    ApiServiceProxies.YearlyRashifalsServiceProxy,
    ApiServiceProxies.TithieServiceProxy,

    // temple
    ApiServiceProxies.AstrologerServiceProxy,
    ApiServiceProxies.AudioServiceProxy,
    ApiServiceProxies.AudioCategoryServiceProxy,
    ApiServiceProxies.BookServiceProxy,
    ApiServiceProxies.BookCategoryServiceProxy,
    ApiServiceProxies.EventServiceProxy,
    ApiServiceProxies.ProgramServiceProxy,
    ApiServiceProxies.SiteServiceProxy,
    ApiServiceProxies.TempleServiceProxy,


    // ASP.NET Zero helpers

    ApiServiceProxies.AuditLogServiceProxy,
    ApiServiceProxies.CachingServiceProxy,
    ApiServiceProxies.ChatServiceProxy,
    ApiServiceProxies.CommonLookupServiceProxy,
    ApiServiceProxies.EditionServiceProxy,
    ApiServiceProxies.FriendshipServiceProxy,
    ApiServiceProxies.HostSettingsServiceProxy,
    ApiServiceProxies.InstallServiceProxy,
    ApiServiceProxies.LanguageServiceProxy,
    ApiServiceProxies.NotificationServiceProxy,
    ApiServiceProxies.OrganizationUnitServiceProxy,
    ApiServiceProxies.PermissionServiceProxy,
    ApiServiceProxies.ProfileServiceProxy,
    ApiServiceProxies.RoleServiceProxy,
    ApiServiceProxies.SessionServiceProxy,
    ApiServiceProxies.TenantServiceProxy,
    ApiServiceProxies.TenantDashboardServiceProxy,
    ApiServiceProxies.TenantSettingsServiceProxy,
    ApiServiceProxies.TimingServiceProxy,
    ApiServiceProxies.UserServiceProxy,
    ApiServiceProxies.UserLinkServiceProxy,
    ApiServiceProxies.UserLoginServiceProxy,
    ApiServiceProxies.WebLogServiceProxy,
    ApiServiceProxies.AccountServiceProxy,
    ApiServiceProxies.TokenAuthServiceProxy,
    ApiServiceProxies.TenantRegistrationServiceProxy,
    ApiServiceProxies.HostDashboardServiceProxy,
    ApiServiceProxies.PaymentServiceProxy,
    ApiServiceProxies.DemoUiComponentsServiceProxy,
    ApiServiceProxies.InvoiceServiceProxy,
    ApiServiceProxies.SubscriptionServiceProxy,
    ApiServiceProxies.InstallServiceProxy,
    ApiServiceProxies.UiCustomizationSettingsServiceProxy,
    ApiServiceProxies.PayPalPaymentServiceProxy,
    ApiServiceProxies.StripePaymentServiceProxy,
    ApiServiceProxies.DashboardCustomizationServiceProxy,
    ApiServiceProxies.WebhookEventServiceProxy,
    ApiServiceProxies.WebhookSubscriptionServiceProxy,
    ApiServiceProxies.WebhookSendAttemptServiceProxy,
    ApiServiceProxies.UserDelegationServiceProxy,
    ApiServiceProxies.DynamicPropertyServiceProxy,
    ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
    ApiServiceProxies.DynamicEntityPropertyServiceProxy,
    ApiServiceProxies.DynamicPropertyValueServiceProxy,
    ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
    ApiServiceProxies.TwitterServiceProxy,
    { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
    { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
    { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
  ],
})
export class ServiceProxyModule { }
